<template>
  <div id="mian" class="clear">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>机构名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="机构名称"
          ></el-input>
        </div>
        <div class="item">
          <i>激活状态</i>
          <el-select
            v-model="query.enabledStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已激活" :value="true"></el-option>
            <el-option label="未激活" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists"
          >查询</el-button
        >
          <el-button
            v-auth="'ACCOUNT:ORG:ORGS/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 200px">
            <p>创建时间</p>
          </td>
          <td style="width: 230px">
            <p>机构名称</p>
          </td>
          <td style="width: 204px">
            <p>机构编号</p>
          </td>
          <td style="width: 227px">
            <p>激活状态</p>
          </td>
          <td style="width: 177px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 200px">
            <p>{{ v.createTime }}</p>
          </td>
          <td style="width: 230px">
            <p>{{ v.orgName }}</p>
          </td>
          <td style="width: 204px">
            <p>{{ v.orgNo }}</p>
          </td>
          <td style="width: 227px">
            <p v-show="OFFON_ACTIVATE != 'ACCOUNT:ORG:ORGS/ACTIVATE'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
            <p v-auth="'ACCOUNT:ORG:ORGS/ACTIVATE'">
              <el-switch
                v-model="v.enabledStatus"
                @change="setStatus(v)"
                active-color="#13ce66"
              >
              </el-switch>
            </p>
          </td>
          <td style="width: 177px">
            <p>
              <i
                v-auth="'ACCOUNT:ORG:ORGS/DETAIL'"
                class="lianjie"
                @click="detailTab(v.orgId)"
                >详情</i
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { organizationsLists, setStatus } from "@/api/user/organizations.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        enabledStatus: "",
        pageNo: 1,
        pageSize: 10,
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
      OFFON_ACTIVATE:'ACCOUNT:ORG:ORGS/ACTIVATE',
    };
  },
  computed: {
    ...mapState({
      organQueryDetail: "user_organQuery",
    }),
  },
  created() {
    if (this.organQueryDetail) {
      this.query = this.organQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setOrganQuery: "user_setOrganQuery",
      setOrgan: "user_setOrgan",
    }),
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      organizationsLists(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.organizations;
          console.log(this.tabData,"this.tabData")
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //新增按钮
    addclickHander() {
      this.setOrgan(null);
      this.$router.replace("/user/add_organization_jbxx");
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //详情
    detailTab(id) {
      this.$router.replace({
        name: "organization_xp",
        query: {
          pageType: "edit",
          id: id,
        },
      });
    },
    //启用、关闭机构
    setStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            orgId: value.orgId,
            enabledStatus: value.enabledStatus,
          };
          setStatus(data).then((res) => {
            if (!res) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_ACTIVATE = 'ACCOUNT:ORG:ORGS/ACTIVATE';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:ORG:ORGS/ACTIVATE') !== -1 ){
            this.OFFON_ACTIVATE = 'ACCOUNT:ORG:ORGS/ACTIVATE';
          }else{
            this.OFFON_ACTIVATE = "";
          }
        }

    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setOrganQuery(data);
  },
};
</script>
<style scoped>

</style>
